
import { Component, Vue } from 'vue-property-decorator';
import SingleHeader from '@/components/singleHeader.vue';
import SingleFooter from '@/components/singleFooter.vue';
import DetailTips from '../component/DetailTips.vue';
import DemandMain from '../component/DemandMain.vue';
import CenterTips from '../component/CenterTips.vue';
import HtmlInfo from '../component/HtmlInfo.vue';
import ProductSpecs from '../component/ProductSpecs.vue';
import Customer from '../component/Customer.vue';
import ExplainFile from '../component/ExplainFile.vue';
import Recommend from '../component/Recommend.vue';
import ApplyDock from '../component/ApplyDock.vue';
import { RESOURCE_CONFIG } from '@/utils/global';

@Component({
    name: "DemandDetail",
    components: {
        SingleHeader,
        SingleFooter,
        DetailTips,
        DemandMain,
        CenterTips,
        HtmlInfo,
        ProductSpecs,
        Customer,
        ExplainFile,
        Recommend,
        ApplyDock,
    },
})
export default class DemandDetail extends Vue {
    private baseUrlIframe = process.env.VUE_APP_URL;
    private RESOURCE_CONFIG: any = RESOURCE_CONFIG;
    private active: number = 2;
    private contentTitle: string = "详情页";
    private useInfo: any = {}; // 用户信息
    private tabsTag: any = "tab1";
    private goodsId: any = '';
    private goodsTab: any = {};
    private sellerTab: any = {};
    private refererArr: any = [];
    private infoFlag: boolean = false;
    // DetailTips
    private tipsIndex: number = 0; // 0，不显示；1，认证提示；2，切换账号提示
    // end
    private applyDockFlag: boolean = false;

    private created() {
        if (this.$route.fullPath.indexOf('goodsId=') > -1) {
            this.goodsId = unescape(this.$route.query.goodsId + '');
            this.goodsDetail();
        }
        if (this.$route.fullPath.indexOf('active=') > -1) {
            const active = Number(this.$route.query.active + '');
            if (active) {
                this.active = active;
            } else {
                this.active = 2;
            }
        }
    }
    // vue的mounted方法中, 写设置页面滚动条的这个方法
    private mounted() {
        // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0, 0);
    }
    private goodsDetail() {
        this.infoFlag = false;
        const paramData: any = {};
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/mall/demand/${this.goodsId}`)
        .then((res: any) => {
            this.infoFlag = true;
            this.goodsTab = res;
            this.goodsTab.goodsPriceType = res.type;
            this.infoFlag = true;
        });
    }
    private tabsChange(name: any) {
        this.tabsTag = name;
    }
    private loginTips() {
        this.$confirm('请登录后操作', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            this.$router
            .push({
                path: '/login',
                query: {
                    redirect: window.location.href,
                },
            }).catch((err) => err);
        }).catch(() => {
            // ...
        });
    }
    private receiveCloseDetailTips() {
        this.tipsIndex = 0;
    }
    private gotoPage(index: number) {
        if (index === 1) {
            this.$router.push('/index');
        } else if (index === 2) {
            // window.location.href = this.baseUrlIframe + '/mall/index.htm';
            this.$router.push('/mall');
        } else if (index === 5) {
            this.$router.push('/industryLibrary');
        }
    }
    private async clickHandleGoodsMain(num: number) {
        if (!await this.$isLogin()) {
            this.loginTips();
            return;
        }
        if (num === 1) {
            // 对接
            this.useInfo = this.$store.state.userInfo;
            if (this.useInfo.userType === 1) {
                this.tipsIndex = 2;
                return;
            }
            if (this.useInfo.userType === 2 && this.useInfo.certStatus !== 2) {
                this.tipsIndex = 1;
                return;
            }
            this.applyDockFlag = true;
        }
    }
    private handleCloseApplyDock(num: any) {
        this.applyDockFlag = false;
        if (num === 1) {
            this.goodsDetail();
        }
    }
}
