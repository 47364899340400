
import { Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: 'ExplainFile',
})
export default class ExplainFile extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private goodsTab!: any; // 接收父组件传过来的值

    private viewWord() {
        const domain = process.env.VUE_APP_URL + '';
        const baseDomain = domain.indexOf('//cloud') > -1 ? domain : 'https://scloud.morewis.com';
        // 根据后缀判断文件类型
        let fileSuffix = "";
        try {
            const fileArr = this.goodsTab.wordUrl.split(".");
            fileSuffix = fileArr[fileArr.length - 1];
        } catch (err) {
            fileSuffix = "";
        }
        fileSuffix = fileSuffix.toLowerCase();
        const fileUrl = `${baseDomain}${this.goodsTab.wordUrl}`;
        const fileName = this.goodsTab.goodsTitle + '-使用说明文档';
        if (fileSuffix === 'pdf') {
            window.open(fileUrl);
        } else if (fileSuffix === 'doc' || fileSuffix === 'docx'
            || fileSuffix === 'xls' || fileSuffix === 'xlsx'
            || fileSuffix === 'ppt') {
            window.open(`https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}`);
        } else if (fileSuffix === 'txt') {
            this.downloadFile(fileUrl, fileName);
        } else {
            window.open(fileUrl);
        }
    }
    private async downloadFile(url: any, fileName: any, tipsFlag: boolean = false) {
        if (tipsFlag) {
            this.$message.success('文件下载中，请稍等');
        }
        const fileUrl = url;
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        this.triggerADownload(URL.createObjectURL(blob), fileName);
    }
    private triggerADownload(blob: any, fileName: any) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = blob;
        if (fileName && fileName !== '') {
            a.download = fileName;
        }
        a.click();
        document.body.removeChild(a);
    }
}
