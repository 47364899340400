
import { RESOURCE_CONFIG, SERVER_CONFIG, SERVER_CONFIG_MC_FOUR } from '@/utils/global';
import { Component, Vue, Prop, Emit} from 'vue-property-decorator';
import PicZoom from "@/components/PicZoom/PicZoom.vue";
import CenterTips from '../component/CenterTips.vue';
import { debounce } from "@/utils/utils";

@Component({
    name: 'GoodsMain',
    components: {
        PicZoom,
        CenterTips,
    },
})
export default class GoodsMain extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private goodsTab!: any; // 接收父组件传过来的值
    private apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private defaultProductImg: any = require('@/assets/images/detail/moreCloud.png');
    private RESOURCE_CONFIG: any = RESOURCE_CONFIG;
    private middleImg: string = ''; // 中图图片地址
    private carouselStart: number = 0;
    private imgArr: any = [];
    // 应用相关存储配置
    private dbTypeArr: any = [];
    private dbType: number = 1; // 1: pg;2: oracle;
    private buyTypeArr: any = [];
    private buyType: number = 1; // 1: 月;2: 年; 3:永久; 4：伙伴价格； 3,4永久变买断
    private dbActive: number = 1; // 1：缩略状态；2：展开选择
    private shrinkStr: string = ''; // 缩略时的字符串
    // 数据库的cpu
    private dbCpuSetList: any = [];
    private dbFreeCpu: number = 0;
    private dbCpu: number = 0;
    // 数据库的memory
    private dbMemorySetList: any = [];
    private dbFreeMemory: number = 0;
    private dbMemory: number = 0;
    // 数据库的HardDisk
    private dbHardDiskSetList: any = [];
    private dbFreeHardDisk: number = 0;
    private dbHardDisk: number = 0;
    // 数据库的timeNumber
    private dbTimeNumberSetList: any = [];
    private dbFreeTimeNumber: number = 0;
    private dbTimeNumber: number = 0;
    // 服务器的timeNumber
    private serverTimeNumberSetList: any = [];
    private serverFreeTimeNumber: number = 0;
    // 产品默认时间列表
    private appTimeNumberSetList: any = [];
    private appTimeList: any = [];
    // 服务器的授权用户数
    private userNumber: number = 0;
    // 规格
    private skuArr: any = [];
    private skuId: number = -1; // -1：默认规格
    // 优惠信息
    private discountItems: any = [];
    // 买断的信息
    private orderDetailGrantTab: any = {};
    private orderDetailGrantNumber: number = 0;
    // 服务器免费配置
    private serverFreeTab: any = {};
    private serverFreeFourTab: any = {};
    // 所有商品价格
    private allProductTab: any = {
        appDiscountMoney: 0, // 产品优惠
        appMoney: 0, // 产品价格
        dbDiscountMoney: 0, // 数据库优惠
        dbMoney: 0, // 数据库价格
        licenseDiscountMoney: 0, // 授权用户数优惠
        licenseMoney: 0, // 授权用户数价格
    };
    private showDialog: boolean = false;
    private serverIdMoreDev: string = '-1';
    private serverFourList: any = [];
    @Emit('clickHandle')
    private clickHandleTodo(num: number): number {
        return num;
    }
    private created() {
        this.imgArr = [].concat(this.goodsTab.pictures);
        if (this.imgArr.length > 0) {
            this.carouselStart = 0;
            this.middleImg = this.apiProxy + this.imgArr[this.carouselStart];
        } else {
            this.middleImg = this.defaultProductImg;
        }
        const setInfo = localStorage.getItem('set_info_api');
        if (setInfo === null || setInfo === '' || setInfo === undefined) {
            this.getSetInfo();
        } else {
            this.init();
        }
    }
    private init() {
        console.log('init');
        this.switchSuk(true);
    }
    // 切换规格时候需要重置的信息
    private switchSuk(firstFlag = false) {
        this.dbType = 1;
        this.buyType = 1;
        this.dbActive = 1;
        this.goodsTab.orderDetailTimeNumber = 1;
        this.orderDetailGrantNumber = 30;
        if (firstFlag) {
            this.getSkuTab();
        }
        this.getDBTab();
        this.getDBTypeTab();
        this.getDiscountTab();
        this.getBuyTab();
        this.$nextTick(() => {
            this.relationDBTime();
            this.assembleGoodsAndPrice();
        });
    }
    private assembleGoodsAndPrice() {
        this.getPrice();
    }
    private assembleGoodsProductList() {
        let orderGoodsSceneType = RESOURCE_CONFIG.APP_GOODSSCENETYPE;
        let dbTypeValue = 0;
        let suiteName = 'mc';
        if (this.goodsTab.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
            orderGoodsSceneType = RESOURCE_CONFIG.APP_GOODSSCENETYPE;
            dbTypeValue = this.dbType;
            suiteName = 'mc';
        } else if (this.goodsTab.goodsPriceType === RESOURCE_CONFIG.SAAS_GOODSPRICETYPE) {
            orderGoodsSceneType = RESOURCE_CONFIG.SAAS_GOODSSCENETYPE;
            dbTypeValue = 0;
            suiteName = 'saas';
            // SaaS续费
            if (this.goodsTab.isPurchasedSaas) {
                orderGoodsSceneType = RESOURCE_CONFIG.SAAS_GOODSSCENETYPE_RENEW;
            }
        }
        const goodsList = [];
        // 把关联安装商品个数传到 父组件中 需要计算服务器资源使用
        let installRelatedGoodsLen = 0;
        if (this.goodsTab && this.goodsTab.installGoodsRelatedList) {
            // 符合数据库的 关联安装商品数量
            const conformDBList = this.goodsTab.installGoodsRelatedList
                .filter((el: any) => el.dbTypes.includes(dbTypeValue));
            installRelatedGoodsLen = conformDBList.length;
        }
        const appGoods: any = {
            goodsId: this.goodsTab.id, // 选中商品传给后端的值
            orderDetailNumber: 1, // 选中商品数量(固定不变的)
            orderDetailTimeNumber: this.goodsTab.orderDetailTimeNumber, // 时长
            orderGoodsSceneType,
            suiteName,
            dbType: dbTypeValue,
            mcVersion: this.goodsTab.mcVersion,
            installRelatedLen: installRelatedGoodsLen, // 只是为了父组件中使用和api无关
        };
        let serverTimeNumber = 0;
        const dbTimeNumMax = Math.max.apply(Math, this.dbTimeNumberSetList.map((item: any) => item.number ));
        // dbTimeNumberSetList 和服务器的选择时间一致
        if (this.goodsTab.orderDetailTimeNumber > dbTimeNumMax) {
            serverTimeNumber = dbTimeNumMax;
        } else if (this.goodsTab.orderDetailTimeNumber > this.serverFreeTimeNumber) {
            serverTimeNumber = this.goodsTab.orderDetailTimeNumber;
        } else {
            serverTimeNumber = this.serverFreeTimeNumber;
        }
        appGoods.serverTimeNumber = serverTimeNumber;
        if (this.skuId !== -1) {
            appGoods.skuId = this.skuId;
        }
        if (this.goodsTab.mcVersion === '4.0' && this.serverIdMoreDev && this.serverIdMoreDev.toString() !== '-1') {
            appGoods.serverId = this.serverIdMoreDev;
        }
        // 如果是选择oracle数据库，需要再应用里面添加相关数据库配置
        if (this.dbType === 2
            && this.goodsTab.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE
            && this.goodsTab.id !== RESOURCE_CONFIG.MOREDEV_GOODSID) {
            appGoods.cpu = this.dbCpu;
            appGoods.memory = this.dbMemory;
            appGoods.harddisk = this.dbHardDisk;
        }
        goodsList.push(appGoods);
        if (this.dbType === 2) {
            const dbItemData = {
                goodsId: RESOURCE_CONFIG.ORACLE_GOODSID, // 选中商品传给后端的值
                appName: 'Oracle',
                orderDetailNumber: 1, // 选中商品数量(累加)
                orderDetailTimeNumber: this.dbTimeNumber, // 时长
                cpu: this.dbCpu,
                memory: this.dbMemory,
                harddisk: this.dbHardDisk,
                orderGoodsSceneType: RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE,
            };
            goodsList.push(dbItemData);
        }
        if ((this.buyType === 3 || this.buyType === 4) && this.orderDetailGrantNumber === undefined) {
            this.$message.warning('请输入有效的授权用户数');
            return [];
        }
        if ((this.buyType === 3 || this.buyType === 4)
            && this.goodsTab.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
            const grantNumberData: any = {
                goodsId: RESOURCE_CONFIG.GRANT_GOODSID, // 选中商品传给后端的值
                appName: '授权用户数',
                orderDetailNumber: this.orderDetailGrantNumber, // 选中商品数量(累加)
                orderDetailTimeNumber: 999, // 时长
                orderGoodsSceneType: RESOURCE_CONFIG.GRANT_GOODSPRICETYPE,
                isBuyout: true,
            };
            if (this.skuId !== -1) {
                grantNumberData.skuId = this.skuId;
            }
            goodsList.push(grantNumberData);
        }
        return goodsList;
    }
    private getSetInfo() {
        // 得到系统商品相关的配置
        this.$httpService.getData({}, '/apiProxy/api/frontend/goods/set-info')
        .then((res: any) => {
            localStorage.setItem('set_info_api', JSON.stringify(res));
            this.init();
        });
    }
    // 切换图片
    private tabPicture(item: any, index: number) {
        this.middleImg = this.apiProxy + item;
        this.carouselStart = index;
    }
    // 组装数据库配置
    private getDBTab() {
        if (localStorage.getItem('set_info_api') === '' || localStorage.getItem('set_info_api') === undefined) {
            this.$message.error('数据错误');
            return;
        }
        const res = JSON.parse(localStorage.getItem('set_info_api') + '');
        // 配置数据库的Cpu
        this.dbCpuSetList = [];
        this.dbFreeCpu =
            res.dbFreeSet.cpu > 0 ? res.dbFreeSet.cpu : res.dbCpuSetList[0].number;
        this.dbCpu = this.dbFreeCpu;
        res.dbCpuSetList.filter((item: any) => {
            // if (item.number >= this.dbFreeCpu) {
                this.dbCpuSetList.push(item);
            // }
        });
        // 配置数据库的Memory
        this.dbMemorySetList = [];
        this.dbFreeMemory =
            res.dbFreeSet.memory > 0 ? res.dbFreeSet.memory : res.dbMemorySetList[0].number;
        this.dbMemory = this.dbFreeMemory;
        res.dbMemorySetList.filter((item: any) => {
            // if (item.number >= this.dbFreeMemory) {
                this.dbMemorySetList.push(item);
            // }
        });
        // 配置数据库的HardDisk
        this.dbHardDiskSetList = [];
        this.dbFreeHardDisk =
            res.dbFreeSet.hardDisk > 0 ? res.dbFreeSet.hardDisk : res.dbHardDiskSetList[0].number;
        this.dbHardDisk = this.dbFreeHardDisk;
        res.dbHardDiskSetList.filter((item: any) => {
            // if (item.number >= this.dbFreeHardDisk) {
                this.dbHardDiskSetList.push(item);
            // }
        });
        // 配置数据库的TimeNumber
        this.dbTimeNumberSetList = [];
        this.dbFreeTimeNumber =
            res.dbFreeSet.freeTimeNumber > 0 ? res.dbFreeSet.freeTimeNumber : res.timeNumberSetList[0].number;
        this.dbTimeNumber = this.dbFreeTimeNumber;
        res.timeNumberSetList.filter((item: any) => {
            // if (item.number >= this.dbFreeTimeNumber) {
            this.dbTimeNumberSetList.push(item);
            // }
        });
        // 配置服务器的TimeNumber
        this.serverTimeNumberSetList = [];
        this.serverFreeTimeNumber =
            res.serverFreeSet.freeTimeNumber > 0 ?
                res.serverFreeSet.freeTimeNumber : res.timeNumberSetList[0].number;
        res.timeNumberSetList.filter((item: any) => {
            // if (item.number >= this.serverFreeTimeNumber) {
            this.serverTimeNumberSetList.push(item);
            // }
        });

        // 得到免费服务器的授权用户数
        res.userLimitList.filter((item: any) => {
            if (item.price === 0) {
                this.userNumber = item.number;
            }
        });

        // 获得3.0服务器的免费配置
        this.serverFreeTab = res.serverFreeSet;
        // 获得4.0服务器的免费配置
        this.serverFreeFourTab = res.serverFreeSet4;
    }
    // 组装数据库类型
    private getDBTypeTab() {
        if (this.goodsTab.goodsPriceType && this.goodsTab.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE
            && this.goodsTab.databaseTypes && this.goodsTab.databaseTypes.length > 0) {
            this.dbTypeArr = [];
            this.goodsTab.databaseTypes.forEach( (item: any) => {
                if (item.type === 1) {
                    this.dbTypeArr.push({
                        dbType: 1,
                        dbTypeName: 'PostgreSQL',
                    });
                } else if (item.type === 2) {
                    this.dbTypeArr.push({
                        dbType: 2,
                        dbTypeName: 'Oracle',
                    });
                }
            });
            if (this.dbTypeArr.length > 0) {
                this.dbTypeArr.sort((a: any, b: any) => a.dbType - b.dbType);
                this.$nextTick (() => {
                    this.dbType = this.dbTypeArr[0].dbType;
                });
            }
        }
    }
    // 组装 优惠
    private getDiscountTab() {
        this.discountItems = [];
        // 永久价格为0的时候，不显示按月 按年
        if (this.skuId === -1) {
            this.discountItems = [].concat(this.goodsTab.discountItems);
        } else {
            this.goodsTab.skuList.forEach((item: any) => {
                if (item.id === this.skuId) {
                    this.discountItems = [].concat(item.discountItems);
                }
            });
        }
    }
    // 组装 购买方式 按月 按年 永久
    private getBuyTab() {
        this.buyTypeArr = [];
        // 永久价格为0的时候，不显示按月 按年
        let priceItems: any = [];
        if (this.skuId === -1) {
            priceItems = [].concat(this.goodsTab.priceItems);
        } else {
            this.goodsTab.skuList.forEach((item: any) => {
                if (item.id === this.skuId) {
                    priceItems = [].concat(item.priceItems);
                }
            });
        }
        if (priceItems && priceItems.length > 0) {
            priceItems.forEach((item: any) => {
                if (item.type === 2) {
                    this.buyTypeArr.push({
                        buyType: 1,
                        buyTypeName: '按月',
                    });
                } else if (item.type === 3) {
                    this.buyTypeArr.push({
                        buyType: 2,
                        buyTypeName: '按年',
                    });
                } else if (item.type === 1) {
                    this.buyTypeArr.push({
                        buyType: 3,
                        buyTypeName: '买断',
                        minimumLicenses: item.minimumLicenses || 30,
                    });
                } else if (item.type === 4) {
                    this.buyTypeArr.push({
                        buyType: 4,
                        buyTypeName: '买断',
                        minimumLicenses: item.minimumLicenses || 30,
                    });
                }
            });
        }
        if (this.buyTypeArr.length > 0) {
            this.buyTypeArr.sort((a: any, b: any) => a.buyType - b.buyType);
            this.buyType = this.buyTypeArr[0].buyType;
        }
        this.getAppTimeList();
    }
    // 组装时间列表 月 年
    private getAppTimeList() {
        if (localStorage.getItem('set_info_api') === '' || localStorage.getItem('set_info_api') === undefined) {
            this.$message.error('数据错误');
            return;
        }
        const res = JSON.parse(localStorage.getItem('set_info_api') + '');
        // 配置产品时间列表
        this.appTimeNumberSetList = res.appTimeNumberSetList;

        this.appTimeList = [];
        if (this.appTimeNumberSetList.length === 0) {
            return;
        }
        this.appTimeNumberSetList.forEach((item: any) => {
            if (this.buyType === 1 && item.number > 0 && item.number < 12) {
                this.appTimeList.push(item);
            }
            if (this.buyType === 2 && item.number >= 12) {
                this.appTimeList.push(item);
            }
        });
        if (this.appTimeList.length > 0) {
            this.goodsTab.orderDetailTimeNumber = this.appTimeList[0].number;
        }
        if (this.buyType === 3 || this.buyType === 4) {
            this.goodsTab.orderDetailTimeNumber = 999;
            this.orderDetailGrantTab = this.buyTypeArr.find((item: any) => item.buyType === this.buyType);
            this.orderDetailGrantNumber = this.orderDetailGrantTab.minimumLicenses;
        }
    }
    private relationDBTime() {
        // 数据库配置自定义状态时，配置信息就不重置了，时间也不和应用的一致呢
        if (this.dbActive === 2) {
            return;
        }
        const dbTimeNumMax = Math.max.apply(Math, this.dbTimeNumberSetList.map((item: any) => item.number ));
        if (this.goodsTab.orderDetailTimeNumber > dbTimeNumMax) {
            this.dbTimeNumber = dbTimeNumMax;
        } else {
            this.dbTimeNumber = this.goodsTab.orderDetailTimeNumber;
        }
        // 买断的时候，默认 数据库时长也给一年
        if (this.buyType === 3 || this.buyType === 4) {
            this.dbTimeNumber = 12;
        }
        this.dbCpu = this.dbFreeCpu;
        this.dbMemory = this.dbFreeMemory;
        this.dbHardDisk = this.dbFreeHardDisk;
        const dbTimeNum = (this.dbTimeNumber >= 12) ? this.dbTimeNumber / 12 + '年' : this.dbTimeNumber % 12 + '个月';
        this.shrinkStr = `${this.dbCpu}vCpu + ${this.dbMemory}G + ${this.dbHardDisk}G + ${dbTimeNum}`;
    }
    private buyClick(buyClickType: number) {
        if (buyClickType !== this.buyType) {
            this.buyType = buyClickType;
            this.getAppTimeList();
            this.relationDBTime();
            this.assembleGoodsAndPrice();
        }
    }
    private selectTimeNumber() {
        this.relationDBTime();
        this.assembleGoodsAndPrice();
    }
    private async dbClick(dbClickType: number, dbClickName: string) {
        if (dbClickType !== this.dbType) {
            this.dbType = dbClickType;
            if (dbClickType === 2) {
                this.dbActive = 1;
                this.relationDBTime();
            }
            this.assembleGoodsAndPrice();
        }
    }
    private getDBPrice() {
        this.assembleGoodsAndPrice();
    }
    private setDBActive(dbActiveNum: number) {
        if (dbActiveNum !== this.dbActive) {
            this.dbActive = dbActiveNum;
            this.relationDBTime();
            this.assembleGoodsAndPrice();
        }
    }
    get getDiscountPrice() {
        return parseFloat(this.allProductTab.appMoney).toFixed(2);
    }
    get getOriginalPrice() {
        return parseFloat(this.allProductTab.appMoney + this.allProductTab.appDiscountMoney).toFixed(2);
    }
    get getDBDiscountPrice() {
        return parseFloat(this.allProductTab.dbMoney).toFixed(2);
    }
    get getDBOriginalPrice() {
        return parseFloat(this.allProductTab.dbMoney + this.allProductTab.dbDiscountMoney).toFixed(2);
    }
    get getLicenseDiscountPrice() {
        return parseFloat(this.allProductTab.licenseMoney).toFixed(2);
    }
    get getLicenseOriginalPrice() {
        return parseFloat(this.allProductTab.licenseMoney + this.allProductTab.licenseDiscountMoney).toFixed(2);
    }
    private getPrice() {
        const paramData: any = {};
        const goodsList = this.assembleGoodsProductList();
        if (goodsList.length === 0) {
            return new Promise((resolve, reject) => {
                resolve({});
            });
        }
        paramData.goodsList = goodsList;
        paramData.userCheck = false;
        return new Promise((resolve, reject) => {
            this.$httpService.postData(paramData, `/apiProxy/api/frontend/goods/price`)
            .then((res: any) => {
                this.allProductTab = {
                    appDiscountMoney: res.appDiscountMoney, // 产品优惠
                    appMoney: res.appMoney, // 产品价格
                    dbDiscountMoney: res.dbDiscountMoney, // 数据库优惠
                    dbMoney: res.dbMoney, // 数据库价格
                    licenseDiscountMoney: res.licenseDiscountMoney, // 授权用户数优惠
                    licenseMoney: res.licenseMoney, // 授权用户数价格
                };
                resolve(res);
            }).catch((err) => {
                resolve(err);
            });
        });
    }
    private getSkuTab() {
        this.skuArr = [];
        if (this.goodsTab.skuList && this.goodsTab.skuList.length > 0) {
            if (this.goodsTab.priceItems && this.goodsTab.priceItems.length > 0) {
                this.skuArr.push({
                    skuId: -1,
                    skuName: '默认规格',
                });
            }
            this.goodsTab.skuList.forEach( (item: any) => {
                this.skuArr.push({
                    skuId: item.id,
                    skuName: item.name,
                });
            });
            if (this.skuArr.length > 0) {
                this.skuArr.sort((a: any, b: any) => a.skuId - b.skuId);
                this.skuId = this.skuArr[0].skuId;
            }
        }
    }
    private skuClick(skuId: number) {
        if (skuId !== this.skuId) {
            this.skuId = skuId;
            this.switchSuk();
        }
    }
    // 立即购买
    private async buyProduct() {
        if (!await this.$isLogin()) {
            // this.loginTips();
            this.clickHandleTodo(5);
            return;
        }
        const useInfo = this.$store.state.userInfo;
        if (useInfo.userType === 1) {
            // this.tipsIndex = 2;
            this.clickHandleTodo(3);
            return;
        }
        if (useInfo.userType === 2 && useInfo.certStatus !== 2) {
            // this.tipsIndex = 1;
            this.clickHandleTodo(4);
            return;
        }
        // 4.0的产品需要添加serverId
        if (this.goodsTab.mcVersion === '4.0') {
            new Promise((resolve: any) => {
                this.getCompanyServerCreated(resolve);
            }).then(() => {
                this.buyProductReq();
            });
        } else {
            this.buyProductReq();
        }
    }
    private async buyProductReq() {
        const paramData: any = {};
        const goodsList = this.assembleGoodsProductList();
        if (goodsList.length === 0) {
            return;
        }
        paramData.goodsList = goodsList;
        // 已选购产品中 是否 包含授权用户数
        const selectedGrantList = goodsList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.GRANT_GOODSID);
        let buyFlag = 2; // 默认为2，配置不足信息直接弹出；值为1的时候，是不弹出相应信息的，产品是4.0的时候需要特殊处理
        if (this.goodsTab.mcVersion === '4.0') {
            buyFlag = 1;
        }
        paramData.preventRepeat = 'detail';
        this.$httpService.postData(paramData, `/apiProxy/api/frontend/orders/create`, {}, buyFlag)
        .then((res: any) => {
            this.$router
            .push({
                path: '/company/orderConfirm',
                query: {
                    goodsData: JSON.stringify(goodsList),
                    addGoods: selectedGrantList.length > 0 ? '' : '1',
                },
            }).catch((err) => err);
        }).catch((err: any) => {
            // 未领服务器
            if (err.code === 400010) {
                this.getCompanyServer();
            }
            // 配置过低的情况
            if (buyFlag === 1 && (err.code === 3004 || err.code === 3005 || err.code === 3006 || err.code === 400013)) {
                let msg = `云服务器"<span style="color:#409eff">${this.computedServerName}</span>"的配置过低，
                    如需部署新的应用，请先升级云服务器配置，或切换其他云服务器。`;
                if (err.code === 400013) {
                    msg = `云服务器"<span style="color:#409eff">${this.computedServerName}</span>"已过期，
                        如需部署新的应用，请先续费云服务器，或切换其他云服务器。`;
                }
                this.$confirm(msg, '提示', {
                    showClose: false,
                    confirmButtonText: '切换云服务器',
                    cancelButtonText: '取消',
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    // 确定
                    this.showDialog = true;
                }).catch(() => {
                    // 取消
                });
            }
        });
    }
    // 获得个人的 服务器列表
    private getCompanyServer() {
        const goodsList = this.assembleGoodsProductList();
        if (goodsList.length === 0) {
            return;
        }
        const paramUserId = this.$store.state.userInfo.id;
        if (!paramUserId) {
            this.$alert("用户信息正在加载中，请稍后！！!", '提示', {
                showClose: false,
                confirmButtonText: '我知道了',
                callback: (action) => {
                    // ...
                },
            });
            return;
        }
        // 已选购产品中 是否 包含授权用户数
        const selectedGrantList = goodsList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.GRANT_GOODSID);
        this.$httpService.getData({}, '/apiProxy/api/frontend/users/company-server/' + paramUserId)
        .then((res: any) => {
            if (this.goodsTab.mcVersion === '3.0' || this.goodsTab.mcVersion === '4.0') {
                let serverTimeNumber = 0;
                let serverCpu = 0;
                let serverMemory = 0;
                let serverHardDisk = 0;
                const dbTimeNumMax = Math.max.apply(Math, this.dbTimeNumberSetList.map((item: any) => item.number ));
                // dbTimeNumberSetList 和服务器的选择时间一致
                if (this.goodsTab.orderDetailTimeNumber > dbTimeNumMax) {
                    serverTimeNumber = dbTimeNumMax;
                } else if (this.goodsTab.orderDetailTimeNumber > this.serverFreeTimeNumber) {
                    serverTimeNumber = this.goodsTab.orderDetailTimeNumber;
                } else {
                    serverTimeNumber = this.serverFreeTimeNumber;
                }
                if (this.goodsTab.mcVersion === '3.0') {
                    const hasMCThirdTrial = res.serverInfoList
                        .some((item: any) => item.serverType  === SERVER_CONFIG.SERVER_TYPE_TRIAL);
                    if (hasMCThirdTrial) {
                        const objMCThirdTrial = res.serverInfoList
                            .find((item: any) => item.serverType  === SERVER_CONFIG.SERVER_TYPE_TRIAL);
                        // 当时产品数量1，记录一下。如果有试用数据再总体添加到一起
                        let hasAppLen = 1;
                        // 查看已有应用有多少个 包括试用的，因为试用的应用有正式服务器后 试用的应用需要部署到 正式服务器上
                        hasAppLen =
                            objMCThirdTrial.userSysTypeInfoList ? objMCThirdTrial.userSysTypeInfoList.length + 1 : 1;
                        const userSysTypeSomeLen = objMCThirdTrial.userSysTypeInfoList
                            .filter((elItem: any) => elItem.goodsId === this.goodsTab.id).length;
                        hasAppLen -= userSysTypeSomeLen;
                        if (this.dbType === 2 && userSysTypeSomeLen > 0) {
                            hasAppLen += 1;
                        }
                        if (hasAppLen <= 2) {
                            serverCpu = SERVER_CONFIG.TWO_VCUP;
                            serverMemory = SERVER_CONFIG.TWO_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG.TWO_VCUP_HARDDISK;
                        } else if (hasAppLen > 2 && hasAppLen <= 4) {
                            serverCpu = SERVER_CONFIG.FOUR_VCUP;
                            serverMemory = SERVER_CONFIG.FOUR_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG.FOUR_VCUP_HARDDISK;
                        } else if (hasAppLen > 4 && hasAppLen <= 6) {
                            serverCpu = SERVER_CONFIG.SIX_VCUP;
                            serverMemory = SERVER_CONFIG.SIX_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG.SIX_VCUP_HARDDISK;
                        } else if (hasAppLen > 6 && hasAppLen <= 8) {
                            serverCpu = SERVER_CONFIG.EIGHT_VCUP;
                            serverMemory = SERVER_CONFIG.EIGHT_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG.EIGHT_VCUP_HARDDISK;
                        } else if (hasAppLen > 8 && hasAppLen <= 12) {
                            serverCpu = SERVER_CONFIG.TWELVE_VCUP;
                            serverMemory = SERVER_CONFIG.TWELVE_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG.TWELVE_VCUP_HARDDISK;
                        }
                    } else {
                        serverCpu = SERVER_CONFIG.TWO_VCUP;
                        serverMemory = SERVER_CONFIG.TWO_VCUP_MEMORY;
                        serverHardDisk = SERVER_CONFIG.TWO_VCUP_HARDDISK;
                    }
                } else if (this.goodsTab.mcVersion === '4.0') {
                    const hasMCFourTrial = res.serverInfoList
                        .some((item: any) => item.serverType  === SERVER_CONFIG_MC_FOUR.SERVER_TYPE_TRIAL);
                    const installGoodsRelatedList = this.goodsTab.installGoodsRelatedList || [];
                    if (hasMCFourTrial) {
                        const objMCFourTrial = res.serverInfoList
                            .find((item: any) => item.serverType  === SERVER_CONFIG_MC_FOUR.SERVER_TYPE_TRIAL);
                        // 当时产品数量1，记录一下。如果有试用数据再总体添加到一起,
                        // 20230509还有一个平台的数量
                        // 1是应用的数量
                        let hasAppFOurLen = 1;
                        // 查看已有应用有多少个 包括试用的，因为试用的应用有正式服务器后 试用的应用需要部署到 正式服务器上
                        // 如果是选择oracle数据库，需要把数量加上去，因为试用服务器 默认使用pg数据库的，这种情况下要两个数据库的应用共存
                        // 如果试用服务器中，应用列表中一个是应用的goodId，一个是开发平台的goodId情况
                        hasAppFOurLen +=
                            objMCFourTrial.userSysTypeInfoList ? objMCFourTrial.userSysTypeInfoList.length : 0;
                        // 试用服务器，应用列表中一个是应用的goodId
                        const userSysTypeSomeLen = objMCFourTrial.userSysTypeInfoList
                            .filter((elItem: any) => elItem.goodsId === this.goodsTab.id).length;
                        hasAppFOurLen -= userSysTypeSomeLen;
                        // 如果选择购买时是oracle数据库，因为试用服务器是pg数据库，这样转正就需要pg版和oracle版共存的
                        if (this.dbType === 2 && userSysTypeSomeLen > 0) {
                            hasAppFOurLen += 1;
                        }
                        if (RESOURCE_CONFIG.MOREDEV_GOODSID !== this.goodsTab.id) {
                            // 1是开发平台的数量
                            hasAppFOurLen += 1;
                            // 试用服务器，应用列表中一个是开发平台的goodId情况，并且要过滤当前商品不是开发平台
                            const moreDevLen = objMCFourTrial.userSysTypeInfoList
                                .filter((elItem: any) => elItem.goodsId === RESOURCE_CONFIG.MOREDEV_GOODSID).length;
                            hasAppFOurLen -= moreDevLen;
                            // 如果选择购买时是oracle数据库，因为试用服务器是pg数据库，这样转正就需要pg版和oracle版共存的
                            if (this.dbType === 2 && moreDevLen > 0) {
                                hasAppFOurLen += 1;
                            }
                        }

                        // 查看关联安装商品 里面是否有 试用服务器的goodId，有就不添加数量。
                        // 如果试用服务器应用列表中，有相关关联安装商品。这种情况比较极端
                        if (this.goodsTab && this.goodsTab.installGoodsRelatedList) {
                            // 符合数据库的 关联安装商品数量
                            const conformDBList = this.goodsTab.installGoodsRelatedList
                                .filter((el: any) => el.dbTypes.includes(this.dbType));
                            conformDBList.forEach((relatedItem: any) => {
                                hasAppFOurLen += 1;
                                const userRelatedSomeLen = objMCFourTrial.userSysTypeInfoList
                                    .filter((elItem: any) => elItem.goodsId === relatedItem.goodsId).length;
                                hasAppFOurLen -= userRelatedSomeLen;
                                // 如果选择购买时是oracle数据库，因为试用服务器是pg数据库，这样转正就需要pg版和oracle版共存的
                                if (this.dbType === 2 && userRelatedSomeLen > 0) {
                                    hasAppFOurLen += 1;
                                }
                            });
                        }
                        console.log('hasAppFOurLen', hasAppFOurLen);
                        if (hasAppFOurLen <= 2) {
                            serverCpu = SERVER_CONFIG_MC_FOUR.FOUR_VCUP;
                            serverMemory = SERVER_CONFIG_MC_FOUR.FOUR_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG_MC_FOUR.FOUR_VCUP_HARDDISK;
                        } else if (hasAppFOurLen > 2 && hasAppFOurLen <= 4) {
                            serverCpu = SERVER_CONFIG_MC_FOUR.SIX_VCUP;
                            serverMemory = SERVER_CONFIG_MC_FOUR.SIX_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG_MC_FOUR.SIX_VCUP_HARDDISK;
                        } else if (hasAppFOurLen > 4 && hasAppFOurLen <= 6) {
                            serverCpu = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP;
                            serverMemory = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP_HARDDISK;
                        } else {
                            serverCpu = SERVER_CONFIG_MC_FOUR.OTHER_VCUP;
                            serverMemory = SERVER_CONFIG_MC_FOUR.OTHER_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG_MC_FOUR.OTHER_VCUP_HARDDISK;
                        }
                    } else {
                        // serverCpu = SERVER_CONFIG_MC_FOUR.FOUR_VCUP;
                        // serverMemory = SERVER_CONFIG_MC_FOUR.FOUR_VCUP_MEMORY;
                        // serverHardDisk = SERVER_CONFIG_MC_FOUR.FOUR_VCUP_HARDDISK;
                        let installRelatedGoodsLen = 0;
                        if (this.goodsTab && this.goodsTab.installGoodsRelatedList) {
                            // 符合数据库的 关联安装商品数量
                            const conformDBList = this.goodsTab.installGoodsRelatedList
                                .filter((el: any) => el.dbTypes.includes(this.dbType));
                            installRelatedGoodsLen = conformDBList.length;
                        }
                        const installLen = 2 + installRelatedGoodsLen;
                        if (installLen <= 2) {
                            serverCpu = SERVER_CONFIG_MC_FOUR.FOUR_VCUP;
                            serverMemory = SERVER_CONFIG_MC_FOUR.FOUR_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG_MC_FOUR.FOUR_VCUP_HARDDISK;
                        } else if (installLen > 2 && installLen <= 4) {
                            serverCpu = SERVER_CONFIG_MC_FOUR.SIX_VCUP;
                            serverMemory = SERVER_CONFIG_MC_FOUR.SIX_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG_MC_FOUR.SIX_VCUP_HARDDISK;
                        } else if (installLen > 4 && installLen <= 6) {
                            serverCpu = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP;
                            serverMemory = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP_HARDDISK;
                        } else {
                            serverCpu = SERVER_CONFIG_MC_FOUR.OTHER_VCUP;
                            serverMemory = SERVER_CONFIG_MC_FOUR.OTHER_VCUP_MEMORY;
                            serverHardDisk = SERVER_CONFIG_MC_FOUR.OTHER_VCUP_HARDDISK;
                        }
                    }
                }
                const serverGoods = {
                    goodsId: RESOURCE_CONFIG.SERVER_GOODSID, // 选中商品传给后端的值
                    appName: '云服务器',
                    orderDetailNumber: 1, // 选中商品数量(固定不变的)
                    orderDetailTimeNumber: serverTimeNumber, // 时长
                    cpu: serverCpu,
                    memory: serverMemory,
                    harddisk: serverHardDisk,
                    orderGoodsSceneType: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE,
                    dbType: this.dbType,
                };
                const userNumberGoods = {
                    goodsId: RESOURCE_CONFIG.USERNUMBER_GOODSID, // 选中商品传给后端的值
                    appName: '云用户数',
                    orderDetailNumber: 1, // 选中商品数量(固定不变的)
                    orderDetailTimeNumber: serverTimeNumber, // 时长
                    orderGoodsSceneType: RESOURCE_CONFIG.USERNUMBER_GOODSSCENETYPE,
                    userNumber: this.userNumber,
                };
                if (this.buyType === 3 || this.buyType === 4) {
                    // 买断的时候 给一个服务器的时长 给免费配置
                    if (this.goodsTab.mcVersion === '3.0') {
                        serverGoods.orderDetailTimeNumber = this.serverFreeTab.freeTimeNumber;
                        userNumberGoods.orderDetailTimeNumber = this.serverFreeTab.freeTimeNumber;
                    } else if (this.goodsTab.mcVersion === '4.0') {
                        serverGoods.orderDetailTimeNumber = this.serverFreeFourTab.freeTimeNumber;
                        userNumberGoods.orderDetailTimeNumber = this.serverFreeFourTab.freeTimeNumber;
                    }
                }
                goodsList.push(serverGoods);
                goodsList.push(userNumberGoods);
                // 新开4.0产品需要添加一个 开发平台
                if (this.goodsTab.mcVersion === '4.0' && this.goodsTab.id !== RESOURCE_CONFIG.MOREDEV_GOODSID) {
                    const appGoodsObj = goodsList
                        .find((el: any) => el.goodsId === this.goodsTab.id);
                    const moreDevGoods: any = {
                        suiteName: 'MoreDev 低代码开发平台',
                        goodsId: RESOURCE_CONFIG.MOREDEV_GOODSID, // 选中商品传给后端的值
                        skuId: process.env.VUE_APP_URL === 'https://cloud.morewis.com'
                            ? RESOURCE_CONFIG.MOREDEV_SKUID
                            : undefined, // 开发平台 生产环境中有规格
                        orderDetailNumber: 1, // 选中商品数量(固定不变的)
                        orderDetailTimeNumber: serverGoods.orderDetailTimeNumber, // 时长 跟云服务器时长保持一致
                        orderGoodsSceneType: appGoodsObj.orderGoodsSceneType,
                        dbType: appGoodsObj.dbType, // appGoodsObj.dbType
                        mcVersion: '4.0',
                    };
                    goodsList.push(moreDevGoods);
                }
            }
            this.$router
            .push({
                path: '/company/orderConfirm',
                query: {
                    goodsData: JSON.stringify(goodsList),
                    addGoods: selectedGrantList.length > 0 ? '' : '1',
                },
            }).catch((err) => err);
        });
    }
    private getCompanyServerCreated(resolve: any) {
        // 得到个人信息
        const paramUserId = this.$store.state.userInfo.id;
        if (!paramUserId) {
            this.$alert("用户信息正在加载中，请稍后！！!", '提示', {
                showClose: false,
                confirmButtonText: '我知道了',
                callback: (action) => {
                    // ...
                },
            });
            return;
        }
        this.$httpService.getData({preventRepeat: 999}, '/apiProxy/api/frontend/users/company-server/' + paramUserId)
        .then((res: any) => {
            this.serverFourList = [];
            res.serverInfoList.forEach((item: any) => {
                if (item.serverType === SERVER_CONFIG_MC_FOUR.SERVER_TYPE) {
                    item.serverId = item.serverId.toString();
                    this.serverFourList.push(item);
                }
            });
            if (this.serverFourList.length > 0 && this.goodsTab.id !== RESOURCE_CONFIG.MOREDEV_GOODSID) {
                this.serverIdMoreDev = this.serverFourList[0].serverId;
            } else {
                this.serverIdMoreDev = '-1';
            }
            resolve();
        });
    }
    private handleChangeInputNumber() {
        if (this.orderDetailGrantNumber === undefined) {
            this.orderDetailGrantNumber = this.orderDetailGrantTab.minimumLicenses;
        }
        debounce(() => {
            this.getPrice();
        }, 1000)();
    }
    get computedServerName() {
        const tab = this.serverFourList.find((el: any) => el.serverId.toString() === this.serverIdMoreDev.toString());
        if (this.serverIdMoreDev.toString() === '-1') {
            return '新开云服务器';
        }
        return tab && tab.serverName ? tab.serverName : '请选择服务器';
    }
    get companyServerFourObj() {
        const tab = this.serverFourList
            .find((el: any) => el.serverId.toString() === this.serverIdMoreDev.toString());
        return tab || {};
    }
    private handleCommand(com: string) {
        this.serverIdMoreDev = com;
    }
    private confirmSwitch() {
        this.showDialog = false;
        if (this.serverIdMoreDev.toString() === '-1') {
            this.getCompanyServer();
        } else {
            this.buyProduct();
        }
    }
}
