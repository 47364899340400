
import { Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: 'DetailHtml',
})
export default class DetailHtml extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private goodsTab!: any; // 接收父组件传过来的值
}
