
import { Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: 'Customer',
})
export default class Customer extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private goodsTab!: any; // 接收父组件传过来的值
    private sellerTab: any = {};
    private async customLink() {
        if (!await this.$isLogin()) {
            this.loginTips();
            return;
        }
        const sellerTab = this.sellerTab;
        const baseCustom = '/gateway/rest/v1/chat/morewisCloud/wisLogin?moreToken=';
        const customUrl = `${sellerTab.ntalkChatUrl}${baseCustom}${sellerTab.ntalkToken}&toUser=${sellerTab.customAccount}`;
        window.open(customUrl);
    }
    private created() {
        if (this.goodsTab.category === 1) {
            this.getCustomerService();
        } else if (this.goodsTab.category === 2) {
            this.getDemandCustomerService();
        }
    }
    private getCustomerService() {
        const paramData: any = {};
        paramData.goodsId = this.goodsTab.id;
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/mall/goods/${this.goodsTab.id}/customer-service`)
        .then((res: any) => {
            this.sellerTab = res;
        });
    }
    private getDemandCustomerService() {
        const paramData: any = {};
        paramData.goodsId = this.goodsTab.id;
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/mall/demand/${this.goodsTab.id}/customer-service`)
        .then((res: any) => {
            this.sellerTab = res;
        });
    }
    private loginTips() {
        this.$confirm('请登录后操作', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            this.$router
            .push({
                path: '/login',
                query: {
                    redirect: window.location.href,
                },
            }).catch((err) => err);
        }).catch(() => {
            // ...
        });
    }
}
