
import { RESOURCE_CONFIG } from '@/utils/global';
import { Component, Vue, Prop, Emit} from 'vue-property-decorator';
import PicZoom from "@/components/PicZoom/PicZoom.vue";
import CenterTips from '../component/CenterTips.vue';

@Component({
    name: 'DemandMain',
    components: {
        PicZoom,
        CenterTips,
    },
})
export default class DemandMain extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private goodsTab!: any; // 接收父组件传过来的值
    private apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private defaultProductImg: any = require('@/assets/images/detail/moreCloud.png');
    private RESOURCE_CONFIG: any = RESOURCE_CONFIG;
    private middleImg: string = ''; // 中图图片地址
    private carouselStart: number = 0;
    private imgArr: any = [];
    @Emit('clickHandle')
    private clickHandleTodo(num: number): number {
        return num;
    }
    private created() {
        this.imgArr = [].concat(this.goodsTab.pictures);
        if (this.imgArr.length > 0) {
            this.carouselStart = 0;
            this.middleImg = this.apiProxy + this.imgArr[this.carouselStart];
        } else {
            this.middleImg = this.defaultProductImg;
        }
    }
    // 切换图片
    private tabPicture(item: any, index: number) {
        this.middleImg = this.apiProxy + item;
        this.carouselStart = index;
    }
}
