
import { RESOURCE_CONFIG } from '@/utils/global';
import { Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: 'Recommend',
})
export default class Recommend extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private goodsTab!: any; // 接收父组件传过来的值
    private refererArr: any = [];
    private apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private defaultProductImg: any = require('@/assets/images/detail/moreCloud.png');
    private RESOURCE_CONFIG: any = RESOURCE_CONFIG;
    private gotoDetail(item: any) {
        if (this.goodsTab.category === 1) {
            // this.$router
            // .push({
            //     path: '/goods/detail',
            //     query: {
            //         goodsId: item.id,
            //         active: '2',
            //     },
            // }).catch((err) => err);
            const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
            window.open(`${baseUrl}/goods/detail?goodsId=${item.id}&active=2`);
        } else if (this.goodsTab.category === 2) {
            // this.$router
            // .push({
            //     path: '/demand/detail',
            //     query: {
            //         goodsId: item.id,
            //         active: '2',
            //     },
            // }).catch((err) => err);
            const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
            window.open(`${baseUrl}/demand/detail?goodsId=${item.id}&active=2`);
        }
    }
    private created() {
        if (this.goodsTab.category === 1) {
            this.getRecommendList();
        } else if (this.goodsTab.category === 2) {
            this.getDemandRecommendList();
        }
    }
    private getRecommendList() {
        const paramData: any = {};
        paramData.goodsId = this.goodsTab.id;
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/mall/goods/${this.goodsTab.id}/related`)
        .then((res: any) => {
            this.refererArr = res;
        });
    }
    private getDemandRecommendList() {
        const paramData: any = {};
        paramData.goodsId = this.goodsTab.id;
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/mall/demand/${this.goodsTab.id}/related`)
        .then((res: any) => {
            this.refererArr = res;
        });
    }
}
