
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({
    name: 'DetailTips',
})
export default class DetailTips extends Vue {
    private useInfo: any = {}; // 用户信息
    private imgs: any =  [
        require('@/assets/images/detail/shi.png'),
        require('@/assets/images/detail/tan.png'),
    ];
    private tipsSrc: any = null;
    private tipsMsg: string = '尊敬的用户，为保障您的账户安全，请您完成实名认证。';
    private tipsBtnLab: string = '去认证';
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Prop({
        type: Number,
        default: 0,
    }) private tipsIndex!: number; // 接收父组件传过来的值
    @Watch('tipsIndex') // 监听父组件传过来的值
    private tipsIndexFun(value: number) {
        if (value !== 0) {
            this.showDialog = true;
        }
        if (value === 1) {
            this.tipsSrc = this.imgs[0];
            this.useInfo = this.$store.state.userInfo;
            if (this.useInfo.certStatus === 0) {
                this.tipsMsg = '尊敬的用户，为保障您的账户安全，请您完成实名认证。';
                this.tipsBtnLab = '去认证';
            } else if (this.useInfo.certStatus === 1) {
                this.tipsMsg = '您当前提交的实名认证正在审核中，预计将1-3个工作日完成。';
                this.tipsBtnLab = '确定';
            } else if (this.useInfo.certStatus === -1) {
                this.tipsMsg = '您当前提交的实名认证未通过审核，请重新提交申请。';
                this.tipsBtnLab = '重新提交';
            } else {
                this.tipsMsg = '数据异常';
                this.tipsBtnLab = '关闭';
            }
        } else if (value === 2) {
            this.tipsSrc = this.imgs[1];
            this.tipsMsg = '为了您产品的正常使用，请把账户切换成企业登录后再购买。';
            this.tipsBtnLab = '去切换';
        }
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeTodo(0); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeTodo(num: number): any {
        this.showDialog = false;
        return num;
    }
    private handleAuth() {
        if (this.tipsIndex === 1) {
            if (this.useInfo.certStatus === 0) {
                this.$router
                .push({
                    path: '/company/certification',
                    query: {
                        redirect: window.location.href,
                    },
                }).catch((err) => err);
                this.closeTodo(1);
            } else if (this.useInfo.certStatus === 1) {
                this.closeTodo(1);
            } else if (this.useInfo.certStatus === -1) {
                this.$router
                .push({
                    path: '/company/certification',
                    query: {
                        redirect: window.location.href,
                    },
                }).catch((err) => err);
                this.closeTodo(1);
            } else {
                this.closeTodo(1);
            }
        } else if (this.tipsIndex === 2) {
            this.$router.push("/person").catch((err) => err);
            this.closeTodo(1);
        }
    }
}
