
import { Component, Vue } from 'vue-property-decorator';
import SingleHeader from '@/components/singleHeader.vue';
import SingleFooter from '@/components/singleFooter.vue';
import DetailTips from '../component/DetailTips.vue';
import GoodsMain from '../component/GoodsMain.vue';
import CenterTips from '../component/CenterTips.vue';
import HtmlInfo from '../component/HtmlInfo.vue';
import ProductSpecs from '../component/ProductSpecs.vue';
import Customer from '../component/Customer.vue';
import ExplainFile from '../component/ExplainFile.vue';
import Recommend from '../component/Recommend.vue';
import ApplyDialog from "@/components/RetainInfoDialog/ApplyDialog.vue";
import ApplyConfirm from "@/components/RetainInfoDialog/ApplyConfirm.vue";
import PerfectDialog from "@/components/RetainInfoDialog/PerfectDialog.vue";
import { RESOURCE_CONFIG } from '@/utils/global';

@Component({
    name: "ProductDetail",
    components: {
        SingleHeader,
        SingleFooter,
        DetailTips,
        GoodsMain,
        CenterTips,
        HtmlInfo,
        ProductSpecs,
        Customer,
        ExplainFile,
        Recommend,
        ApplyDialog,
        ApplyConfirm,
        PerfectDialog,
    },
})
export default class ProductDetail extends Vue {
    private baseUrlIframe = process.env.VUE_APP_URL;
    private RESOURCE_CONFIG: any = RESOURCE_CONFIG;
    private active: number = 2;
    private contentTitle: string = "详情页";
    private useInfo: any = {}; // 用户信息
    private tabsTag: any = "tab1";
    private goodsId: any = '';
    private goodsTab: any = {};
    private sellerTab: any = {};
    private refererArr: any = [];
    private infoFlag: boolean = false;
    // DetailTips
    private tipsIndex: number = 0; // 0，不显示；1，认证提示；2，切换账号提示
    // end
    // 申请试用 立即体验
    private applyVisible: boolean = false;  // 试用前完善信息弹窗
    private perfectVisible: boolean = false;    // 体验前完善信息弹窗
    private applyConfirmFlg: boolean = false;  // 已认证企业确认试用弹窗
    private experienceItem: any = {};   // 当前体验产品
    private applyContent: object = {};
    private productInfo: any = {};
    // 服务器的授权用户数
    private userNumber: number = 0;

    private created() {
        if (this.$route.fullPath.indexOf('goodsId=') > -1) {
            this.goodsId = unescape(this.$route.query.goodsId + '');
            this.goodsDetail();
        }
        if (this.$route.fullPath.indexOf('active=') > -1) {
            const active = Number(this.$route.query.active + '');
            if (active) {
                this.active = active;
            } else {
                this.active = 2;
            }
        }
    }
    // vue的mounted方法中, 写设置页面滚动条的这个方法
    private mounted() {
        // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0, 0);
    }
    private goodsDetail() {
        this.infoFlag = false;
        const paramData: any = {};
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/mall/goods/${this.goodsId}`)
        .then((res: any) => {
            this.infoFlag = true;
            this.goodsTab = res;
            this.goodsTab.goodsPriceType = res.type;
            this.infoFlag = true;
        });
    }
    private getSetInfo() {
        // 得到系统商品相关的配置
        this.$httpService.getData({}, '/apiProxy/api/frontend/goods/set-info')
        .then((res: any) => {
            localStorage.setItem('set_info_api', JSON.stringify(res));
            // 得到免费服务器的授权用户数
            res.userLimitList.filter((item: any) => {
                if (item.price === 0) {
                    this.userNumber = item.number;
                }
            });
        });
    }
    private tabsChange(name: any) {
        this.tabsTag = name;
    }
    private loginTips() {
        this.$confirm('请登录后操作', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            this.$router
            .push({
                path: '/login',
                query: {
                    redirect: window.location.href,
                },
            }).catch((err) => err);
        }).catch(() => {
            // ...
        });
    }
    private receiveCloseDetailTips() {
        this.tipsIndex = 0;
    }
    private gotoPage(index: number) {
        if (index === 1) {
            this.$router.push('/index');
        } else if (index === 2) {
            // window.location.href = this.baseUrlIframe + '/mall/index.htm';
            this.$router.push('/mall');
        } else if (index === 5) {
            this.$router.push('/industryLibrary');
        }
    }
    private async clickHandleGoodsMain(num: number) {
        if (!await this.$isLogin()) {
            this.loginTips();
            return;
        }
        if (num === 1) {
            // 申请试用
            this.useInfo = this.$store.state.userInfo;
            if (this.useInfo.userType === 2 && this.useInfo.certStatus === 2) {
                this.applyConfirmFlg = true;
                this.applyContent = {
                    id: this.goodsTab.id,
                    name: this.goodsTab.name,
                };
            } else {
                this.applySubmit({
                    id: this.goodsTab.id,
                    name: this.goodsTab.name,
                });
            }
        } else if (num === 2) {
            // 免费体验
            this.experienceNow({
                id: this.goodsTab.id,
                name: this.goodsTab.name,
            });
        } else if (num === 3) {
            // 个人帐号
            this.tipsIndex = 2;
        } else if (num === 4) {
            // 未认证帐号
            this.tipsIndex = 1;
        } else if (num === 5) {
            // 未登录
            this.loginTips();
        }
    }
    private closeExperienceNow(num: number) {
        this.perfectVisible = false;
        if (num === 3) {
            this.experienceNow(this.experienceItem);
        }
    }
    private applyCloseMsg(num: number) {
        this.applyConfirmFlg = false;
        if (num === 3) {
            this.applySubmit(this.applyContent);
        }
    }
    private closeApplyTrial(num: number) {
        this.applyVisible = false;
        if (num === 3) {
            // ...
        }
    }
    // 提交申请
    private applySubmit(row: any) {
        this.$httpService.postData({}, `/apiProxy/api/frontend/goods/${row.id}/apply-trial`)
        .then((res: any) => {
            if (res.url) {
                window.open(res.url);
            } else {
                this.$confirm(res.message, '提示', {
                    confirmButtonText: '我知道了',
                    showCancelButton: false,
                });
            }
        }).catch((err: any) => {
            if (err.code === 1007000501) {  // 打开提交申请试用弹窗
                this.productInfo = row;
                this.applyVisible = true;
            }
        });
    }
    // 立即体验
    private experienceNow(row: any) {
        this.$httpService.postData({}, `/apiProxy/api/frontend/goods/${row.id}/try-experience`)
        .then((res: any) => {
            if (res.url) {
                window.open(res.url);
            }
        }).catch((err: any) => {
            if (err.code === 1004004108) {
                this.experienceItem = row;
                this.perfectVisible = true;
            }
        });
    }
}
