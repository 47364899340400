
import { Component, Vue } from 'vue-property-decorator';
import SingleHeader from '@/components/singleHeader.vue';
import SingleFooter from '@/components/singleFooter.vue';
import DetailTips from './component/DetailTips.vue';
import { RESOURCE_CONFIG } from '@/utils/global';

@Component({
    name: "ProductDetail",
    components: {
        SingleHeader,
        SingleFooter,
        DetailTips,
    },
})
export default class ProductDetail extends Vue {
    private defaultProductImg: any = require('@/assets/images/detail/moreCloud.png');
    private baseUrlIframe = process.env.VUE_APP_URL;
    private active: number = 5;
    private contentTitle: string = "详情页";
    private useInfo: any = {}; // 用户信息
    private tabsTag: any = "tab1";
    private productNumber: number = 1;
    private productTotal: any = 0;
    private goodsId: any = '';
    private goodsTab: any = {};
    private sellerTab: any = {};
    private refererArr: any = [];
    private downloadDomain = 'https://wis3d-pro.morewiscloud.com';
    private infoFlag: boolean = false;
    // DetailTips
    private tipsIndex: number = 0; // 0，不显示；1，认证提示；2，切换账号提示
    // end

    private created() {
        if (this.$route.fullPath.indexOf('goodsId=') > -1) {
            this.goodsId = unescape(this.$route.query.goodsId + '');
            this.goodsDetail();
            this.goodsSeller();
            this.goodsReferer();
        }
        if (this.$route.fullPath.indexOf('active=') > -1) {
            const active = Number(this.$route.query.active + '');
            if (active === 2) {
                this.active = active;
            } else {
                this.active = 5;
            }
        }
    }
    // vue的mounted方法中, 写设置页面滚动条的这个方法
    private mounted() {
        // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0, 0);
    }
    private goodsDetail() {
        this.infoFlag = false;
        this.$httpService.getData({}, `/apiProxy/api/frontend/goods/${this.goodsId}/drive`)
        .then((res: any) => {
            this.goodsTab = res || {};
            this.productTotal = this.goodsTab.price || 0;
            this.infoFlag = true;
        });
    }
    private goodsSeller() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/goods/${this.goodsId}/seller/info`)
        .then((res: any) => {
            this.sellerTab = res  || {};
        });
    }
    private goodsReferer() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/goods/${this.goodsId}/referer`)
        .then((res: any) => {
            this.refererArr = res || [];
        });
    }
    private tabsChange(name: any) {
        this.tabsTag = name;
    }
    private toInteger() {
        if (this.productNumber === undefined) {
            this.productTotal = 0;
        } else {
            const goodsPrice = this.goodsTab.price || 0;
            this.productTotal = Number(this.productNumber * goodsPrice).toFixed(2);
        }
        this.productNumber = this.parser(this.productNumber, 999, 1);
    }
    private parser(value: any, max: number, min: number) {
        if (value === undefined) {
            return min;
        }
        const v = parseInt(value, 10);
        if (!isNaN(v)) {
            if (v > max) {
                return max;
            } else if (v < min) {
                return min;
            } else {
                return v;
            }
        }
        return min;
    }
    private async customLink() {
        if (!await this.$isLogin()) {
            this.loginTips();
            return;
        }
        const sellerTab = this.sellerTab;
        const baseCustom = '/gateway/rest/v1/chat/morewisCloud/wisLogin?moreToken=';
        const customUrl = `${sellerTab.ntalkChatUrl}${baseCustom}${sellerTab.ntalkToken}&toUser=${sellerTab.morewisNumber}`;
        window.open(customUrl);
    }
    // 立即购买
    private async buyProduct() {
        if (!await this.$isLogin()) {
            this.loginTips();
            return;
        }
        this.useInfo = this.$store.state.userInfo;
        if (this.useInfo.userType === 1) {
            this.tipsIndex = 2;
            return;
        }
        if (this.useInfo.userType === 2 && this.useInfo.certStatus !== 2) {
            this.tipsIndex = 1;
            return;
        }
        const inputNumberRef: any = this.$refs.inputNumberRef;
        if (inputNumberRef.currentValue + '' === 'undefined') {
            this.$message.warning('选购数量不能小于1');
            return;
        }
        // /company/orderConfirm?goodsData=${JSON.stringify(this.selectProductList)}`
        const selectProduct: any = {
            goodsId: Number(this.goodsId), // 选中商品传给后端的值
            appName: "",
            orderDetailNumber: this.productNumber, // 选中商品数量(累加)
            orderDetailTimeNumber: 999,
            orderGoodsSceneType: RESOURCE_CONFIG.DRIVE_GOODSSCENETYPE,
            dbType: 0,
        };
        const selectProductList: any = [];
        selectProductList.push(selectProduct);
        this.$router
        .push({
            path: '/company/orderConfirm',
            query: {
                goodsData: JSON.stringify(selectProductList),
            },
        }).catch((err) => err);
    }
    private loginTips() {
        this.$confirm('请登录后操作', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            this.$router
            .push({
                path: '/login',
                query: {
                    redirect: window.location.href,
                },
            }).catch((err) => err);
        }).catch(() => {
            // ...
        });
    }
    private receiveCloseDetailTips() {
        this.tipsIndex = 0;
    }
    private gotoDetail(item: any) {
        // this.$router.push({ path: "/detail", query: { goodsId: item.goodsId }})
        // .catch((err) => err);
        const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
        window.location.href = baseUrl + `/detail?goodsId=${item.goodsId}`;
    }
    private gotoPage(index: number) {
        if (index === 1) {
            this.$router.push('/index');
        } else if (index === 2) {
            window.location.href = this.baseUrlIframe + '/mall/index.htm';
        } else if (index === 5) {
            this.$router.push('/industryLibrary');
        }
    }
    private viewWord() {
        const domain = process.env.VUE_APP_URL + '';
        const baseDomain = domain.indexOf('//cloud') > -1 ? domain : 'https://scloud.morewis.com';
        // 根据后缀判断文件类型
        let fileSuffix = "";
        try {
            const fileArr = this.goodsTab.wordUrl.split(".");
            fileSuffix = fileArr[fileArr.length - 1];
        } catch (err) {
            fileSuffix = "";
        }
        fileSuffix = fileSuffix.toLowerCase();
        const fileUrl = `${baseDomain}${this.goodsTab.wordUrl}`;
        const fileName = this.goodsTab.goodsTitle + '-使用说明文档';
        if (fileSuffix === 'pdf') {
            window.open(fileUrl);
        } else if (fileSuffix === 'doc' || fileSuffix === 'docx'
            || fileSuffix === 'xls' || fileSuffix === 'xlsx'
            || fileSuffix === 'ppt') {
            window.open(`https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}`);
        } else if (fileSuffix === 'txt') {
            this.downloadFile(fileUrl, fileName);
        } else {
            window.open(fileUrl);
        }
    }
    private async downloadFile(url: any, fileName: any) {
        const fileUrl = url;
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        this.triggerADownload(URL.createObjectURL(blob), fileName);
    }
    private triggerADownload(blob: any, fileName: any) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = blob;
        if (fileName && fileName !== '') {
            a.download = fileName;
        }
        a.click();
        document.body.removeChild(a);
    }
    private clickLinkFile(url: any) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.click();
        document.body.removeChild(a);
    }
    private openLinkFile(url: any) {
        window.open(url);
    }
}
