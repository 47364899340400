
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({
    name: 'ApplyDock',
})
export default class ApplyDock extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private showDialog!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private goodsTab!: any; // 接收父组件传过来的值
    private formData: any = {
        contact: '',
    };
    @Watch('showDialog') // 监听父组件传过来的值
    private showDialogFun(value: boolean) {
        if (value) {
            const useInfo = this.$store.state.userInfo;
            this.formData.name = useInfo.nickname;
            this.formData.mobile = useInfo.mobile;
        }
    }
    // 给父组件传值
    @Emit('handleClose')
    private handleCloseTodo(num: number): number {
        const formRef: any = this.$refs.formRef;
        formRef.resetFields();
        return num;
    }
    //  取消按钮
    private closeDialog(num: number) {
        this.handleCloseTodo(num); // 给父组件传值
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.handleCloseTodo(0); // 给父组件传值
    }
    private submit() {
        const paramData: any = {};
        paramData.contact = this.formData.contact;
        this.$httpService.postData(paramData, `/apiProxy/api/frontend/mall/demand/${this.goodsTab.id}/dock`)
        .then((res: any) => {
            this.$message.success('您已申请对接，请耐心等待!');
            this.handleCloseTodo(1); // 给父组件传值
        });
    }
}
